import Button from '@ngin-io/components/public/components/Button/Button.js';
import Heading from '@ngin-io/components/public/components/Heading/Heading.js';
import { useRouter } from 'next/router';
import ErrorLayout from '../ErrorLayout';

const NotFoundPage = () => {
  const router = useRouter();

  return (
    <ErrorLayout>
      <Heading
        as="h1"
        css={{
          marginBottom: 3,
          fontSize: [7, , 8, 9],
          fontWeight: 'normal',
        }}
      >
        Sorry, this page isn’t available
      </Heading>
      <div>
        <p>You have encountered a 404 error</p>
      </div>
      <div css={{ marginTop: 4 }}>
        <Button
          css={{ display: 'inline-block' }}
          as="a"
          onClick={() => {
            return router.push('/');
          }}
          color="ivory"
          variant="secondary"
        >
          Go to homepage
        </Button>
      </div>
    </ErrorLayout>
  );
};

export default NotFoundPage;
