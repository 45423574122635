// ignore unused exports default

import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import NotFound from './404';

const OnboardingPageContainer = dynamic(
  () => {
    return import('../components/pages/OnboardingPageContainer');
  },
  { ssr: false }
);

// ? Reference: https://www.npmjs.com/package/next-seo
const title = `Create Bitcoin Account | Buy & Sell Crypto With AUD | BTC Markets`;
const description = `Create a secure and private account to buy and sell bitcoin, ethereum, XRP and other coins on Australia's leading cryptocurrency exchange.`;
const metaTags = {
  title,
  description,
  canonical: 'https://v2.btcmarkets.net/sign-up',
  openGraph: {
    title,
    description,
  },
};

const SignUp = (props) => {
  if (process.env.NEXT_PUBLIC_ONBOARDING_ENABLE === 'true') {
    return (
      <Fragment>
        <NextSeo {...metaTags} />
        <OnboardingPageContainer {...props} />
      </Fragment>
    );
  } else {
    return <NotFound />;
  }
};

export default SignUp;
