// ignore unused exports default

import { NextSeo } from 'next-seo';
import React from 'react';
import NotFoundPage from '../components/pages/NotFoundPage';

// ? Reference: https://www.npmjs.com/package/next-seo
const metaTags = {
  title: 'BTC Markets - Page Not Found',
  noindex: true,
  nofollow: true,
};

const NotFound = () => {
  return (
    <React.Fragment>
      <NextSeo {...metaTags} />
      <NotFoundPage />
    </React.Fragment>
  );
};

export default NotFound;
